<script>
  import { defineComponent } from 'vue'
  import { useClickToCopy } from 'src/composables/useClickToCopy'

  export default defineComponent({
    name: 'EditorToolbar',
    emits: ['clear', 'prettify'],
    props: {
      config: {
        type: Array,
        required: false
      },
      position: {
        type: String,
        required: false,
        default: 'right'
      },
      code: {
        type: String,
        required: true
      },
      originalCode: {
        type: String,
        required: true
      },
      lang: {
        type: String,
        required: true
      },
      editorRef: {
        required: true
      },
      prettifiable: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        jsonCode: this.$props.code,
        localOriginalCode: this.$props.originalCode, // Even tough the clear btn disabled check uses the prop directly, this is required. Otherwise console error
        isValidJson: true,
        allFolded: false,
        isLoading: true
      }
    },
    computed: {
      toolbarButtons() {
        if (this.$props.config?.length) {
          return this.$props.config
        }
        return [
          'copy',
          'clear'
        ]
      },
      nodes() {
        if(!this.isLoading) return this.$props.editorRef.session.foldWidgets?.filter(item => item === 'start').length ?? 0;
        else return 0;
      }
    },

    methods: {
      clear() {
        this.$emit('clear');
      },

      foldAll() {
        this.allFolded = !this.allFolded;
        const editor = this.$props.editorRef; // Get the Ace Editor instance
        //const editor = this.$refs.editor._editor; // Get the Ace Editor instance

        // Fold all code blocks
        this.allFolded ? editor.session.foldAll() : editor.session.unfold();

        // Optionally, you can also collapse all fold widgets
        editor.session.foldWidgets = [];
        editor.renderer.updateFull();
      },

      prettify() {
        this.$emit('prettify');
      },

      clickToCopy: useClickToCopy(),
      copyEditorValue(event) {
        this.clickToCopy(event, {
          value: this.jsonCode
        })
      },

      isDifferentCode(code1, code2){
        return JSON.stringify(code1) !== JSON.stringify(code2);
      },

      // Wait function for foldWidgets array initialization
      async wait() {
        new Promise((resolve) => {
          setTimeout(() => {
            this.isLoading = false;
            resolve();
          }, 10);
        });
      }
    },

    // foldWidgets array is not immediately initialized on first tick. This fixes it.
    async mounted() {
      this.localOriginalCode = this.jsonCode;
      await this.wait();
      this.$nextTick(() => {
        this.nodes;
      });
    }
  })
</script>

<template>
  <!-- Top Toolbar -->
  <div
      class="q-editor-toolbar flex full-width q-mb-md items-center"
      :class="{'justify-end': $props.position === 'right'}"
  >
    <span class="q-px-sm">
         <q-btn flat round
                padding="none"
                :icon="allFolded ? 'unfold_more' : 'unfold_less'"
                :disable="!jsonCode || !nodes || nodes === 0"
                @click="foldAll"
         />
        <q-tooltip v-if="jsonCode">{{ allFolded ? $t('general.expandAll') : $t('general.collapseAll') }}</q-tooltip>
    </span>

    <span class="q-px-sm" v-if="prettifiable">
         <q-btn flat round
                padding="none"
                :icon="'sunny'"
                :disable="!jsonCode || $props.readonly"
                @click="prettify"
         />
        <q-tooltip v-if="jsonCode">{{ $t('general.prettify') }}</q-tooltip>
      </span>

    <span v-if="toolbarButtons.includes('copy')" class="q-px-sm">
         <q-btn flat round
                padding="none"
                icon="content_copy"
                :disable="!jsonCode"
                @click="copyEditorValue"
         />
        <q-tooltip v-if="jsonCode">{{ $t('general.copyEditorContent') }}</q-tooltip>
      </span>

    <span v-if="toolbarButtons.includes('clear')" class="q-px-sm">
         <q-btn flat round
                padding="none"
                icon="highlight_off"
                :disable="!jsonCode || $props.lang === 'json' && !isDifferentCode(jsonCode, $props.originalCode) || $props.readonly"
                @click.capture.stop="clear"
         />
        <q-tooltip v-if="jsonCode">{{ $t('general.clearEditor') }}</q-tooltip>
      </span>

    <slot name="toolbar-right"></slot>
  </div>

  <!-- Top Toolbar End -->
</template>

<style lang="scss">
  .q-editor-toolbar .q-btn.disabled {
    opacity: .3 !important;
  }
</style>
